import imageCompression from "browser-image-compression";
import { PlateForm } from "../utils/device.context";
import { isMobile } from 'react-device-detect';
import { LogParameter, LogRequestPayload } from "../utils/log.dto";
import axios from "axios";
import { getIp } from "../app/postRequest.service";
import { GaEvent } from "../utils/GA_services";
import { FeedBack } from "../api/homeAPI";
import { userLoginDetails } from "../interfaces/signup.interface";
export const getCookieValue = (name:any) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const postToNativeApp = async(data:any)=>{
  window.postMessage(
    JSON.stringify(data),
    '*'
  );
}

/**
 * Function to receive data from Native App as a callback.
 * @param {Callback} callback 
 */
export const getFromNativeApp = async(callback:any)=>{
  window.onmessage=callback;
}

export const getCopyData=(data:any):any=>{
  return JSON.parse(JSON.stringify(data));
}


export function getPlatformFromCookie(): PlateForm | 'MAVEN|MobileWeb' | 'MAVEN|Web' {
	const platformCookieValue = getCookieValue('platform');
	if (platformCookieValue in PlateForm) {
	  return PlateForm[platformCookieValue as keyof typeof PlateForm];
	}
	return 'MAVEN|MobileWeb'; 
  }
  export const testPhoneNumber = (value: any) => {
		let reg =  /^[6-9]\d{9}$/gi;;
		return reg.test(value);
	};

  export const testEmail = (value: any) => {
	let reg =
	  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(value);
  };
  export const getPlatform = () => {
	  let platform;
	  if (isMobile) {
		  if (getCookieValue('platform')) {
			  if (getCookieValue('platform') == 'ios') {
				  platform = 'MAVEN|IOS'
			  }
			  else if (getCookieValue('platform') == 'android') {
				  platform = 'MAVEN|ANDROID'
			  }
		  }
		  else {
			  platform = "MAVEN|MobileWeb"
		  }
	  } else {
		  platform = 'MAVEN|Web'
	  }
	  return platform;
  }
  export const logAPIStatus = async (logsFields: LogParameter,isResponseRequired?:boolean) => {
	const ip= await getIp();
	const referrer=document.referrer ;
	const payload : LogRequestPayload = {
	  dataLoaded: logsFields?.isDataLoaded,
	  actionOn: logsFields?.actionPage,
	  source: 'MAven',
	  platform: getPlatform(),
	  accessToken: localStorage.getItem('accessToken') || null ,
	  requestBody: logsFields?.requestBody,
	  responseBody: logsFields?.responseBody,
	  entityName:logsFields?.entityName,
	  loginBy: logsFields?.loginBy,
	  ipAdd : ip,
	  referer: referrer ,
	  claimLength :logsFields?.claimLength ,
	  claimID: logsFields?.claimID 
	}
	const url =`${process.env.REACT_APP_BASE_URL}/auth/log`
	let res  = await axios.post(url , payload );
	 if(isResponseRequired){
	  return res;
	 };
	}
export const uploadKycDoc = async (
	files: [],
	apiPath: string,
	fileName: string
): Promise<any> => {
	let data = new FormData();
	for (let i = 0; i < files.length; i++) {
		data.append('fileName', fileName);
		data.append('file', await compressFile(files[i]));
	}
	const headers = {
		accessToken: localStorage.getItem('accessToken'),
		loginToken: localStorage.getItem('loginToken')
	}
	let start: any = Date.now();
	let end: any;
	let status;

	const response = await fetch(
		`${process.env.REACT_APP_BASE_URL}/${apiPath}`,
		{
			method: 'POST',
			body: data,
			headers
		}
	)
		.then((response) => response.json())
		.then((success) => {
			end = Date.now();
			status = true;
			let url = `${process.env.REACT_APP_API_BASE_URL}/${apiPath}`
			// if (url.toLowerCase().includes('/kyc/')) {
			// 	GaPageView('/signzyUpload');
			// }
			return success;
		})
		.catch((error) => {
			status = false;
			end = Date.now();
		});

	const json = await response;
	return json;
};



const blobToFile = (theBlob: Blob, fileName: string): File => {
	return new File([theBlob], fileName, {
		lastModified: new Date().getTime(),
		type: theBlob.type,
	});
};


const isFileImage = (file: any): boolean => {
	return file && file['type'].split('/')[0] === 'image';
};

const compressFile = async (file: File): Promise<any> => {
	if (file instanceof Blob === false) return file;

	try {
		if (isFileImage(file) && file.size / 1024 / 1024 > 1) {
			let options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};
			const compressedFile = await imageCompression(file, options);
			return blobToFile(compressedFile, file.name);
			//} else if (file.type === 'application/pdf' && file.size / 1024 / 1024 > 5) {
			//TODO: Compress pdf
			//  return file;
		} else {
			return file;
		}
	} catch (err) {
		return file;
	}
};

export const LoginGAEvent = (eventName: string, categoryName: string, username: string, UserDetails: any) => {
		GaEvent(eventName, {
			category: categoryName,
			label: `${username ?? ""}` + '_' + `${UserDetails?.deviceId ?? ""}`,
			ErrorMsg: UserDetails?.message??"",
			userProperty:
				JSON.stringify({
					UnameEmailMobile: username ?? "",
					deviceId: UserDetails?.deviceId ?? "",
				})
		})
}

export const GaEventDetails = (eventName: string, categoryName: string, maid: string, UserDetails: any) => {
		GaEvent(eventName, {
		  category: categoryName,
		  label: `${maid??""}`+'_'+`${UserDetails?.benefId??""}`+'_'+`${UserDetails?.primeBenefName??""}`,
		  ErrorMsg: UserDetails?.message??"",
		  maid: maid??"",
		  userProperty:
			  JSON.stringify({
				ClaimId:UserDetails?.actualClaimId??"",
				primeBenefName: UserDetails?.primeBenefName??"",
				polHolderName: UserDetails?.polHolderName??"",    
				
			  })
	  })
}

export const feedBackCommon= async (entityType :string)=>{
    let MA=localStorage.getItem("maid");
    if(MA){
      const getFeedback :any = await FeedBack(MA,entityType);
      if (getFeedback?.data?.statusCode == 204 || getFeedback?.data?.showFeedBack) {
      return true;
      }else{
		return false;
	  }
    }
  }

  export const handleSubmitForm = (url: string, formData: Record<string, string>) => {
    // Create a new form element
    const form = document.createElement('form');
    form.action = url;
    form.method = 'POST';
  
    // Add hidden inputs based on the formData
    Object.keys(formData).forEach((key) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = formData[key];
      form.appendChild(input);
    });
  
    // Append the form to the body (it's invisible)
    document.body.appendChild(form);
  
    // Submit the form programmatically
    form.submit();
  
    // Optional: Remove the form after submission to clean up the DOM
    document.body.removeChild(form);
  };

  export const authenticateUser = (verifiedResponse: any) => {
	  if (verifiedResponse.accessToken) {
		let det: userLoginDetails = new userLoginDetails();
		det.isLoginSkipped = false;
		det.isUserLoggedIn = true;
		localStorage.setItem('accessToken', verifiedResponse.accessToken);
		localStorage.setItem('X-REF-DATA', verifiedResponse.xRefData);
		localStorage.setItem('firstTimeLogin', verifiedResponse.isFirstLogin?.toString());
		localStorage.setItem('isContact','true');
		localStorage.setItem('DownloadAppPrompt', "true");
		sessionStorage.setItem('userDeviceToken', verifiedResponse?.deviceId);
		postToNativeApp({
		  isLinkPolicy:localStorage.getItem('isLinkPolicy') == 'true' ? true : false,
		  accessToken: verifiedResponse?.accessToken || null,
		  accessTokenExpirationTime: verifiedResponse?.expiry?.toString() || null,
		  userDeviceToken: verifiedResponse?.deviceId,
		  xRefData: verifiedResponse.xRefData
		});
	  }
	};
  