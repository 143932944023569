import React from "react";
import { useEffect, useState } from "react";
import { ContactContainer } from "@mediassistrepo/homepage-component";

import {
  sendEmailOtp,
  sendMobileOtp,
  updateBaseProfile,
  updateContactApi,
  verifyOtp,
  verifyOtpProfile,
} from "../api/loginAPI";
import {
  getEmailDataApi,
  getMobileDataApi,
  getUpdateContactDetails,
} from "../api/updateContactAPI";
import { errorMonitor } from "stream";
import Loader from "./shared/Loader";
import { useNavigate } from "react-router";
import { getMaid } from "../utils";
import { IContactOtpPayload } from "../interfaces/updateContact.interface";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { baseProfileAsync, baseProfileSlice, getBaseProfile } from "../reducers/baseProfile.slice";
import { useErrorBoundary } from "react-error-boundary";
import ErrorComponent from "./Error";
import { getBeneficiaries } from "../reducers/beneficiaries.slice";
import { BeneficiaryDetail } from "../interfaces/beneficiaries.interface";
import { contactAsync, getcontact } from "../reducers/updateContact.slice";
import useWindowWidth from "../hooks/getWindowWidth";
interface updateContactContentProps {
  title: string;
  heading: string;
  description: string;
  textForPhone: string;
}

export const menuBarList = [
  {
    title: "Phone",
    completed: false,
    keyword: "phone",
    value: "",
  },
  {
    title: "Email ID",
    completed: false,
    keyword: "emailid",
    value: "",
  },
];
export const menuBarListDesk = [
  {
    title: "Mobile number",
    completed: false,
    keyword: "phone",
    value: "",
  },
  {
    title: "E-mail ID’s",
    completed: false,
    keyword: "emailid",
    value: "",
  },
];

export const updateContactContent: updateContactContentProps[] = [
  {
    title: "phone",
    heading: "Select your mobile number",
    description:
      "Your contact will be used for login, updates, and WhatsApp communication. ",
    textForPhone:
      "Please confirm up to 2 phone numbers. You may delete any outdated information. ",
  },
  {
    title: "emailid",
    heading: "Select your email ID",
    description:
      "Your contact will be used for login, updates, and WhatsApp communication. ",
    textForPhone:
      "Please confirm up to 2 email IDs. You may delete any outdated information. ",
  },
];

interface MenuBarItem {
  title: string;
  completed: boolean;
  keyword: string;
  value: string;
}

require("@mediassistrepo/homepage-component/dist/style.css");

const UpdateContact = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const baseProfile = useAppSelector(getBaseProfile);
  const [isLoader, setIsLoader] = useState(false as boolean);
  const [isError,setIsError]=useState(false)
  const [mobileNoOtp, setmobileNoOtp] = useState(null as any);
  const [emailOtp, setEmailOtp] = useState(null as any);
  const [mobileNoToken, setmobileNoToken] = useState(null as any);
  const [emailOtpToken, setEmailOtpToken] = useState(null as any);
  const [contactData, setContactData] = useState<any>();
  const windowWidth =useWindowWidth()
  const [menuList, setMenuList] = useState<MenuBarItem[]>(windowWidth >768 ?menuBarListDesk:menuBarList);
  const [number, setNumber] = useState([]);
  const [email, setEmail] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>("phone");
  const [updateContactText, setUPdateContactText] = useState(
    updateContactContent?.[0]
  );
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [isVisibleModel, setIsVisiblsModel] = useState<boolean>(false);
  const [isVisibleDelelteModel, setIsVisiblsDeleteModel] =
    useState<boolean>(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isContactDetails, setIsContactDetails] = useState<boolean>(true);
  const [isAlternativeEmail, setIsAlternativeEmail] = useState<boolean>(false);
  const [alterEmailInput, setAlterEmailInput] = useState("");
  const [alterMobileInput, setAlterMobileInput] = useState("");
  const [verifiedMobileNumber, setVerifiedMobileNumber] = useState<any[]>([]);
  const [verifiedEmailId, setVerifiedEmailId] = useState<any[]>([]);
  const [storedValue, setStoreValue] = useState<string>("");
  const [isAddEmailId, setIsAddEmailId] = useState<boolean>(false);
  const [isRemoveAllEmail, setIsRemoveAllEmail] = useState<boolean>(false);
  const [moblieInput, setMobileInput] = useState<string>("");
  const [optNo, setOtpNo] = useState("");
  const [isResetOtp, setisResetOtp] = useState<boolean>(false);
  const [loginValue, setLoginValue] = useState<string>("");
  const [isErrorVal, setErrorVal] = useState<boolean>(false);
  const [userNameForVerify, SetUserNameForVerify] = useState("");
  const beneficiaryDetails = useAppSelector(getBeneficiaries);
  const [loginValueMobile, setLoginValueMobile] = useState<any>({
    mobile: "",
    isValid: false,
  });
  const [loginValueEmail, setLoginValueEmail] = useState<any>({
    email: "",
    isValid: false,
  });
const contactInfo = useAppSelector(getcontact);
  const handleError = () => {
    throw new Error("An error occurred!");
  };



  useEffect(() => {
    if (baseProfile) {
      SetUserNameForVerify(baseProfile?.data?.firstName);
    }
    
  }, [baseProfile]);

   useEffect(() => {
          if(contactInfo && baseProfile?.data?.isMobileVerified ||baseProfile?.data?.isEmailVerified){
            if(baseProfile?.data?.isMobileVerified){
              setLoginValueMobile({ mobile: baseProfile?.data?.mobileNo, isValid: true });
            }
            if(baseProfile?.data?.isEmailVerified){
              setLoginValueEmail({ email: baseProfile?.data?.emailId, isValid: true });
            }
          }
          if(contactInfo && baseProfile){
            getDetails("");
          }
      }, [baseProfile,contactInfo]);
  

  useEffect(() => {
   
  }, [contactInfo]);

  const sendMobileOtps = async (request: any) => {
    setIsLoader(true);
    const payload: IContactOtpPayload = {
      mobile: alterMobileInput,
    };
    try {
      const response: any = await getMobileDataApi(payload);
      if (response?.data?.isSuccess) {
        setIsLoader(false);
        setmobileNoToken(response?.data?.token);
        return response?.data;
      } else {
        setIsLoader(false);
        return response?.data;
      }
    } catch (error) {
      setIsLoader(false);
      return false;
    }
  };

  const getDetails = async (request: any) => {
    setIsLoader(true);
    try {
    
      
      const {  isSuccess } = contactInfo;
      if (isSuccess) {
      setContactData(contactInfo);
      }else {
        setIsError(true)
        setIsLoader(false);
      }

    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoader(false);
    }
  };

  const sendEmailOtps = async (request: any) => {
    setIsLoader(true);
    try {
      const response = await getEmailDataApi(request);
      if (response?.data?.isSuccess) {
        setIsLoader(false);
        setEmailOtpToken(response?.data?.token || "");
        return response?.data;
      }else {
      setIsLoader(false);
      return response?.data;
    }
  } catch (error) {
    setIsLoader(false);
    return false;
  } finally {
      setIsLoader(false);
    }
  };

  const verifyOtpv = async (request: any) => {
    setIsLoader(true);
    try {
      const response = await verifyOtpProfile(request);
      return response.data;
    } catch (error) {
      return null;
    } finally {
      setIsLoader(false);
      setOtpNo("");
      setisResetOtp(!true);
    }
  };

  

  const updateContactAPI = async (altEmailId?:any, isSkipped?:boolean) => {
    setIsLoader(true) 
    let maid =beneficiaryDetails?.beneficiaryDetails.find((x:BeneficiaryDetail)=>x.relationToPrimaryId === 1)?.mediAssistId || baseProfile?.data?.maid;
    ;

    
    const paylad = {
      maid: maid,
      emailId: verifiedEmailId[0]?.email || "", 
      phoneNumber: verifiedMobileNumber[0]?.number || "", 
      altEmailId:isSkipped ? "": altEmailId || verifiedEmailId[1]?.email || "", 
      altMobile: verifiedMobileNumber[1]?.number || "", 
      dbType: "MA",
      isContactsVerified: true,
    };
    
    const payloadBaseProfile = {
      key: "isContactsVerified",
      value: "true",
    };
    
    try {
      setIsLoader(true)
      const response = await updateContactApi(paylad) as any;
  
      if (response?.data?.isSuccess) {
        setIsContactDetails(true);
        setIsAlternativeEmail(false);
        setIsVisiblsModel(true);
        
        dispatch(baseProfileAsync());
        
        const responsebaseProfile = await updateBaseProfile(payloadBaseProfile) as any;
        
        if (responsebaseProfile?.message) {
          setIsLoader(false);
          return  response?.data?.isSuccess;
        } else{
          setIsLoader(false)
          return  response?.data?.isSuccess;
        }
      }else{
        setIsLoader(false);
        return response?.data?.isSuccess;
      }
      

    } catch (error) {
      setIsLoader(false)
      handleError(); 
    } finally {
      setOtpNo(""); 
      setisResetOtp(!true);
    }
  };

  useEffect(() => {
    
    if (contactData) {
      const numberItem = contactData?.data?.mobileNo?.map(
        (number: any) => ({
          number: number,
          isCheck: false,
        })
      );
      if (loginValueMobile?.isValid) {
        const filterLogin=[
          ...numberItem.filter(
            (val: any) => !val?.number?.includes(loginValueMobile?.mobile)
          ),
          {
            number: loginValueMobile?.mobile,
            isCheck: true,
            isPresent: true,
          },
        ]
        setNumber(filterLogin);
      } else {
        const uniqueNumberItems = numberItem.filter((value:any, index:number, self:any) => 
          index === self.findIndex((t:any) => (
            t.number === value.number
          ))
        );
        
        setNumber(uniqueNumberItems);
      }
      const emailItem = contactData?.data?.emailId?.map((email: any) => ({
        email: email,
        isCheck: false,
      }));
      if (loginValueEmail?.isValid) {
        
        setEmail((prev: any) => {
          return [
            ...emailItem.filter(
              (val: any) => !val?.email.includes(loginValueEmail?.email)
            ),
            {
              email: loginValueEmail?.email,
              isCheck: true,
              isPresent: true,
            },
          ];
        });
      } else {
        const seenEmails = new Set();
        const uniqueEmailItems = emailItem.filter((item:any) => {
          if (!seenEmails.has(item.email)) {
            seenEmails.add(item.email);
            return true;
          }
          return false;
        });
        setEmail(uniqueEmailItems);
      }
    }
  }, [contactData]);

  useEffect(() => {
    // setIsRender(false);
    let mobileNo: any[] = [];
    let emailArr: any[] = [];

    number?.forEach((element: any) => {
      if(element?.isPresent){
        mobileNo?.unshift(element);
      }else if (element?.isCheck) {
        mobileNo?.push(element);
      }
    });
    setVerifiedMobileNumber(mobileNo);
    email?.forEach((element: any) => {
      if(element?.isPresent){
        emailArr?.unshift(element);
      }else if(element?.isCheck){
        
        emailArr?.push(element);
      }
    });
    setVerifiedEmailId(emailArr);
  }, [number, email]);

  const handleSkip = () => {
    dispatch(contactAsync());
    dispatch(baseProfileAsync());
    localStorage.setItem("notVerified", "true");
    localStorage.setItem("isLinkPolicy", "false");
    navigate(-1);
  };

  return (
    <div style={{ padding: isVisibleModel ? "" : "0 20px" }}>
      {isLoader && <Loader />}
     {!isError&& contactInfo?.isSuccess && <ContactContainer
        menuList={menuList}
        setMenuList={setMenuList}
        contactInfo={number}
        setContactInfo={setNumber}
        contactEmails={email}
        setContactEmail={setEmail}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        updateContactText={updateContactText}
        setUPdateContactText={setUPdateContactText}
        isDeleteModel={isDeleteModel}
        setIsDeleteModel={setIsDeleteModel}
        isVisibleModelContactVerified={isVisibleModel}
        setIsVisiblsModelContactVerified={setIsVisiblsModel}
        isVisibleDelelteModel={isVisibleDelelteModel}
        setIsVisiblsDeleteModel={setIsVisiblsDeleteModel}
        isOtp={isOtp}
        setIsOtp={setIsOtp}
        isAlternativeEmail={isAlternativeEmail}
        setIsAlternativeEmail={setIsAlternativeEmail}
        alterEmailInput={alterEmailInput}
        setAlterEmailInput={setAlterEmailInput}
        verifiedMobileNumber={verifiedMobileNumber}
        setVerifiedMobileNumber={setVerifiedMobileNumber}
        verifiedEmailId={verifiedEmailId}
        storedValue={storedValue}
        setStoreValue={setStoreValue}
        isRemoveAllEmail={isRemoveAllEmail}
        setIsRemoveAllEmail={setIsRemoveAllEmail}
        isAddEmailId={isAddEmailId}
        setIsAddEmailId={setIsAddEmailId}
        isContactDetails={isContactDetails}
        setIsContactDetails={setIsContactDetails}
        moblieInput={moblieInput}
        setMobileInput={setMobileInput}
        alterMobileInput={alterMobileInput}
        setAlterMobileInput={setAlterMobileInput}
        sendEmailOtps={sendEmailOtps}
        verifyOtpv={verifyOtpv}
        sendMobileOtps={sendMobileOtps}
        optNo={optNo}
        setOtpNo={setOtpNo}
        isResetOtp={isResetOtp}
        setisResetOtp={setisResetOtp}
        handleClickBack={handleSkip}
        navigateTOHome={handleSkip}
        mobileNoToken={mobileNoToken}
        emailOtpToken={emailOtpToken}
        setmobileNoToken={setmobileNoToken}
        setEmailOtpToken={setEmailOtpToken}
        goBackTOHomePage={handleSkip}
        isPrimaryMobile={loginValueMobile?.isValid}
        primaryNo={loginValueMobile?.mobile}
        isPrimaryEmail={loginValueEmail?.isValid}
        primaryEmail={loginValueEmail?.email}
        updateContactAPI={updateContactAPI}
        isError={isErrorVal}
       setError={setErrorVal}
        userNameContactVerify={userNameForVerify}
        setIsLoader={setIsLoader}
      />}
      {isError &&<ErrorComponent error="pageNotFound" />}
    </div>
  );
};

export default UpdateContact;
