import React, { useEffect, useState,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderWeb from './shared/Header';
import { useLocation, useParams } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { getConfig } from '../reducers/config.slice';
import Loader from './shared/Loader';
import SurveyMain from '../common/Survey/SurveyMain';
import Constants from '../constants/Constants';
import { FeedBack, SetNpsFeedBack } from '../api/homeAPI';
import { EntityValue, FeedbackValue, NpsFeedback } from '../common/Nps/nps.models';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import {NpsFeedbackClaimSubmission} from '@mediassistrepo/homepage-component';
import { dataLayerPush } from '../utils/gtm';
import { getBeneficiaries, getBeneficiariesLoading } from '../reducers/beneficiaries.slice';
import { getSalesForceHealthBenefits } from '../reducers/salesForceHealthBenefits.slice';
import { refreshTokenRequest } from '../utils/tokenService';
import { SalesForcePolicy } from '../interfaces/salesforcePolicy.interface';

export default function ClaimSubmission(props: any) {
    const [loading, setLoading] = useState(true)
    const history = useNavigate();
    const params = useParams()
    const config = useAppSelector(getConfig);
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const submissionType =  query.get("benefitsType") || params.submissionType;
    const [buttonClick, setButtonClick] = React.useState(null);
    const [showFeedBack,setShowFeedBack] = React.useState<boolean>(false);
    const [showNpsFeedBack,setShowNpsFeedBack] = React.useState<boolean>(false);
    const [surveyFeedBack,setSurveyFeedBack] = React.useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [clmRefNo,setClmRefNo]= React.useState(null);
    const [priBenefName,setPriBenefName]= React.useState(null); 
    const [benefId,setbenefId]= React.useState(null); 
    const [claimPriBenefId,setClaimPriBenefId]= React.useState(null);
    // States to keep track of remind later counts
    const [claimSubIpdCount, setClaimSubIpdCount] = useState(0);
    const [claimSubOpdCount, setClaimSubOpdCount] = useState(0); 
    const webComponentRef = useRef(null);
    
    const baseProfile = useAppSelector(getBaseProfile);
    const beneficiaryDetails = useAppSelector(getBeneficiaries);
    const beneficiaryLoading = useAppSelector(getBeneficiariesLoading);

    const pribenef = beneficiaryDetails?.beneficiaryDetails.filter((x:any)=>x.relationToPrimaryId === 1);

    const claimSubEntityType = submissionType === 'IPD' ? "CS_NPS_IPD" : submissionType === 'OPD' ? "CS_NPS_OPD" : "CS_NPS_OPD";
    const claimSubSource = submissionType === 'IPD' ? "nps_cs_ipd" : submissionType === 'OPD' ? "nps_cs_opd" : "nps_cs_opd";
    const priMaId = `${baseProfile?.data?.maid}`;

    const [salesforceURL_KEY, setSalesforceURL_KEY] = useState<string | undefined>(undefined);
    
    const salesForceBenefitsResponse = useAppSelector(getSalesForceHealthBenefits);
    const [salesForceBenefits, setSalesForceBenefits] = useState<SalesForcePolicy>();

    useEffect(() => {
      if (query.get("benefitsName")) {
        setSalesforceURL_KEY(query.get("benefitsName"));
      }
    }, []);
    
    const GetSalesForceBenefits = async () => {
      try {
        const benefitDetails = salesForceBenefitsResponse?.benefitDetails ?? {};
        Object.values(benefitDetails).map((el: SalesForcePolicy) => {
          if (el?.benefitAliasName?.includes(salesforceURL_KEY)) {
            setSalesForceBenefits({
              maid: el?.maid,
              benefits: el?.benefits,
              benefitAliasName: el?.benefitAliasName,
              policyId: el?.policyId,
              claimType: el?.claimType,
              previousYearPolId: el?.previousYearPolId,
              isGrouped: el?.isGrouped,
              groupedPolicyName: el?.groupedPolicyName,
              description: el?.description,
              encryptedToken: el?.encryptedToken,
              isSuccess: salesForceBenefitsResponse?.isSuccess
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    };
    
    useEffect(() => {
      GetSalesForceBenefits();
    }, [salesForceBenefitsResponse]);

    useEffect(() => {
        const fetchInitialData = async () => {
            if (!priMaId || !claimSubEntityType) {
                setLoading(false);
                return;
            }

            setLoading(true);

            try {
                const getFeedback = await FeedBack(priMaId, claimSubEntityType);
                if (getFeedback?.data?.feedback_value) {
                    const remindLaterCount = getFeedback?.data?.feedback_value?.remind_later?.count || 0;
                    if (getFeedback?.data?.feedback_value && remindLaterCount < 3 && Object.keys(getFeedback?.data?.feedback_value).length === 0) {
                        setSurveyFeedBack(false);
                    } else if (getFeedback?.data?.feedback_value && Object.keys(getFeedback?.data?.feedback_value).length > 1) {
                        setSurveyFeedBack(true);
                    }
                } else if (getFeedback?.data?.statusCode === 204) {
                    setSurveyFeedBack(false);
                } else {
                    setSurveyFeedBack(true);
                }
            } catch (err) {
                setSurveyFeedBack(true);
            } finally {
                setLoading(false);
            }                        
        };

        fetchInitialData();
    }, [priMaId, claimSubEntityType]);

    useEffect(() => {
        handleCustomEvent(buttonClick)
      }, [buttonClick]);

      const handleCustomEvent = (eventValue: any) => {
        const customEvent = new CustomEvent("back-event", {
          detail: eventValue,
        });
        window.dispatchEvent(customEvent);
      };

    const selectMemberHandler = (e: any) => {
      dataLayerPush(Constants.Instance.SELECT_INSURED_MEMBER, {
        claim_type: e?.detail === "IPD" ? "Hospitalisation" : "Clinic / OPD",
      });
    };

    const ipdSubmissionCtaHandler = (e: any) => {
      dataLayerPush(Constants.Instance.SUBMISSIONS_IPD_CTA_INTERACTION, {
        cta_text: e.detail[0],
        section: e.detail[1],
      });
    };

   useEffect(() => {
     const component: any = document.querySelector(
       "claim-submission-web-component"
     );
     const goBackHandler = (e: any) => {
       history(-1);
     };
     const successHandler = (e: any) => {
       setClmRefNo(e?.detail?.ClaimReferenceNo);
       setShowFeedBack(true);
     };
     const feedbackHandler = (e: any) => {
       const benefIdDetail = `${e?.detail?.detail?.benefId}`;
       const claimPriMaIdDetail = `${e?.detail?.detail?.priMaId}`;
       const benefNameDetail = `${e?.detail?.detail?.benefName}`;
       const claimRefNoDetail = `${e?.detail?.detail?.claimReferenceNumber}`;
       
       setClmRefNo(claimRefNoDetail);
       setPriBenefName(benefNameDetail);
       setbenefId(benefIdDetail);
       setClaimPriBenefId(claimPriMaIdDetail);

       const claimSubEntity =
         submissionType === "IPD"
           ? "CS_NPS_IPD"
           : submissionType === "OPD"
           ? "CS_NPS_OPD"
           : "CS_NPS_OPD";
       fetchNpsClaimSubmissionApi(claimPriMaIdDetail, claimSubEntity);
     };
     const feedbackRemindLaterHandler = (e: any) => {
       claimSubmissionRemindLater(`${e?.detail?.priMaId}`);
       history("/");
     };

     if (component) {
       window.addEventListener("GO_BACK", goBackHandler);
       window.addEventListener("success", successHandler);
       window.addEventListener("feedback", feedbackHandler);
       window.addEventListener(
         "feedbackRemindLater",
         feedbackRemindLaterHandler
       );
       window.addEventListener('selectMember', selectMemberHandler);
       window.addEventListener('ipdSubmissionCta', ipdSubmissionCtaHandler);
       window.addEventListener('TOKEN_EXPIRED', refreshTokenRequest);
     }

     return () => {
       if (component) {
         window.removeEventListener("GO_BACK", goBackHandler);
         window.removeEventListener("success", successHandler);
         window.removeEventListener("feedback", feedbackHandler);
         window.removeEventListener(
           "feedbackRemindLater",
           feedbackRemindLaterHandler
         );
         window.removeEventListener('selectMember', selectMemberHandler);
         window.removeEventListener('ipdSubmissionCta', ipdSubmissionCtaHandler);
         window.removeEventListener('TOKEN_EXPIRED', refreshTokenRequest);
       }
     };
   }, [surveyFeedBack, submissionType, pribenef]);
    
    const remindLater = async (
        type: string,
        setCount: React.Dispatch<React.SetStateAction<number>>,
        entityId: string
    ) => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            const priMaId = entityId || `${baseProfile?.data?.maid}`;
            const response = await FeedBack(priMaId, type);
            let currentCount = 0;
    
            if (response?.data?.feedback_value && response?.data?.feedback_value?.remind_later) {
                currentCount = response?.data?.feedback_value?.remind_later?.count || 0;
            }
    
            const newCount = currentCount + 1;
            setCount(newCount);
    
            if (newCount > 3) {
                history('/');
                return;
            }
    
            const feedbackValue: FeedbackValue = {
                remind_later: {
                    count: newCount
                }
            };
    
            const entityValue: EntityValue = {
                feedback_value: feedbackValue,
                feedback_rating: 0
            };
    
            let requestObject: NpsFeedback = {
                source: claimSubSource,
                entity_Type: claimSubEntityType,
                entity_Id: priMaId,
                entity_Value: entityValue
            };
    
            const updateResponse = await SetNpsFeedBack(requestObject);
            if (updateResponse?.data?.isSuccess) {
                history('/')
            } else {
            }
        } catch (err) {
        } finally {
            setIsSubmitting(false);
        }
    };
    
    
    const claimSubmissionRemindLater = (entityId: string) => {
        remindLater(
            claimSubEntityType!,
            submissionType === 'IPD' ? setClaimSubIpdCount : setClaimSubOpdCount,
            entityId
        );
    };


    const fetchNpsClaimSubmissionApi = async (priMaId: string, entityType : string) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        
        try {
            const getFeedback: any = await FeedBack(priMaId, entityType);
            if (getFeedback?.data?.statusCode === 204 || (getFeedback?.data?.feedback_value && getFeedback?.data?.feedback_value?.remind_later?.count < 3)) {
                setShowNpsFeedBack(true);
            } else {
                setShowNpsFeedBack(false);
            }
        } catch (err) {
        } finally {
            setIsSubmitting(false);
        }
    };

    const feedback = async (data: { sliderValue: number; checkedStates: string[]; remarks: string }) => {

      let feedbackValue: Record<string, any> = {
        'Beneficiary name': priBenefName,
        'Primary beneficiary MA ID': priMaId,
        'Claim ref no': clmRefNo,
        'Mobile': `${baseProfile?.data?.mobileNo}`,
        'Email ID': `${baseProfile?.data?.emailId}`,
        'Do you have any suggestions for improving the claim submission process?': data.remarks
      };
      let feedbackRating = data.sliderValue;
    
      if (data.sliderValue >= 8) {
        feedbackValue["What did you like most about the claim submission process?"] = data.checkedStates;
      } else if (data.sliderValue > 0 && data.sliderValue < 8) {
        feedbackValue["What did you like least about the claim submission process?"] = data.checkedStates;
      }
    
      let entityValue: EntityValue = new EntityValue();
      entityValue.feedback_rating = feedbackRating;
      entityValue.feedback_value = feedbackValue;
  
      let requestObject: any = {
        source: claimSubSource,
        entity_Type: claimSubEntityType,
        entity_Id: priMaId,
        entity_Value: entityValue
      };
  
      try {
        const response = await SetNpsFeedBack(requestObject);
        if (response?.data && response?.data?.isSuccess) {
            setShowNpsFeedBack(true);
        } else {
        }
      } catch (err) {
      }
    };

    
    useEffect(() => {
        if (showNpsFeedBack) {
        document.body.classList.add("overflow-hidden");
        } else {
        document.body.classList.remove("overflow-hidden");
        }
        return () => {
        document.body.classList.remove("overflow-hidden");
        };
    }, [showNpsFeedBack]);

    return (
        <>
        <style>
        {`
          .react-datepicker-popper {
             z-index: 11 !important;
             }
        `}
        </style>
            {(loading || beneficiaryLoading) && <Loader />}
            
                {surveyFeedBack && showFeedBack && (
                    <div className="relative">
                        <SurveyMain pageType="Claim Submission" pageTitle='The claim has been submitted successfully.' Id={clmRefNo} source={"clmsubmit"} entity_Type={"CLM_SUB"} closeFeedbackModal={() => setShowFeedBack(!showFeedBack)} />
                    </div>
                )}
            
               {showNpsFeedBack ?
                    <>
                        <div className="relative">
                            <NpsFeedbackClaimSubmission
                                Id={clmRefNo}
                                benefName={priBenefName}
                                benefId={benefId}
                                priBenefId={claimPriBenefId}
                                source={claimSubSource}
                                entity_Type={claimSubEntityType}
                                remindLater={() => claimSubmissionRemindLater(priMaId)} 
                                onSubmit={feedback}
                            />
                        </div> 
                    </>
                    :
                    <>
                        {localStorage.getItem('source') !== Constants.Instance.MEDIBUDDY && <HeaderWeb
                            isShowBackButton={true}
                            oldHeader={true}
                            name={"Submit Claim"}
                            onBackBtnClick={() => { setButtonClick(!buttonClick) }}
                        />}
                        <div style={{ marginBottom: "100px", paddingTop: "75px" }}>
                           
                            {pribenef && <claim-submission-web-component
                                accessToken={localStorage.getItem("accessToken")}
                                claimType={submissionType}
                                benefitsType={query.get("benefitsType")}
                                homePage={query.get("homePage")}
                                event={buttonClick}
                                isGeneric={config?.isGenericClaimSubmission ? "true" : null}
                                priBenef={JSON.stringify(pribenef)}
                                salesForceBenefits = {JSON.stringify(salesForceBenefits)}
                                ></claim-submission-web-component>}
                        </div>
                    </>
                }
        </>
    )
}