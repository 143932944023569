// src/components/PrivateRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { setAccessToken } from '../utils';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { ssoSamlLoginAsync } from '../reducers/login.slice';
import Loader from '../components/shared/Loader';
import { postToNativeApp } from '../common/helpers';
import { getConfig } from '../reducers/config.slice';


const PrivateRoute = ({consent}:{consent:boolean}) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('accessToken');
    const source = searchParams.get('source'); //I am passing this source from Medibuddy app as 'medibuddy'
    const lat = searchParams.get('lat');
    const long = searchParams.get('long');
    const platform = searchParams.get('platform');
    const isAuthenticated = localStorage.getItem("accessToken"); 
    const [ loading, setLoading ] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const config = useAppSelector(getConfig);

    useEffect(() => {
        if(source){
            localStorage.setItem('source',source) //This was added for MB to MA changes
            localStorage.setItem('lat',lat)
            localStorage.setItem('long',long)
            localStorage.setItem('platform',platform)
        }
        if(accessToken){
            logIn(accessToken);
        } else {
            setLoading(false);
        }
    }, [loading]);

    const logIn = async (token: string) => {
        const res = await dispatch(ssoSamlLoginAsync({ token }));
        if(res && res.payload && res.payload.accessToken) {
            sessionStorage.setItem('userDeviceToken', res.payload?.deviceId);

            postToNativeApp({
                isLinkPolicy:localStorage.getItem('isLinkPolicy') == 'true' ? true : false,
                accessToken: res.payload?.accessToken || null,
                accessTokenExpirationTime: res.payload?.expiry?.toString() || null,
                userDeviceToken: res.payload?.deviceId,
                xRefData: res.payload.xRefData
              });
            window.location.href = window.location.pathname
        }
        setLoading(false);
    }
    

    const redirectCheck = (path:any, config:any) => {
     if( path == "/claimSubmission/OPD" && config && !config.isDomiSubmissionEnable){
        return true;
     }
     if(path == "/claimSubmission/IPD" && config && config.isHospClaimSubmissionDisabled){
        return true;
     }
     else{
        return false;
     }    
    };
    
    if (loading) {
        return <Loader/>;
    }
    
    if(accessToken){
        setAccessToken(accessToken);
    }

    if (!isAuthenticated) {
        // Save the intended route to localStorage
        localStorage.setItem('intendedRoute', location.pathname);
        return <Navigate to="/" />;
    }

    if (isAuthenticated && consent) {
        return <Navigate to="/terms-and-conditions" />;
    }

    if (redirectCheck(window.location.pathname, config)) {
        return <Navigate to="/" />;
    }
    
    return <Outlet />;
};

export default PrivateRoute;