import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { SalesForceBenefitsRequest, SalesForceBenefitsResponseDTO } from '../interfaces/config.interface';
import { claimSubmissionBenefitsApi } from '../api/salesForceAPI';

export interface SalesForceHealthBenefitsState {
  value: SalesForceBenefitsResponseDTO | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SalesForceHealthBenefitsState = {
  value: null,
  status: 'idle',
};

export const salesForceHealthBenefitsAsync = createAsyncThunk(
  '/claim/flex/benefits',
  async (res: SalesForceBenefitsRequest) => {
    const response = await claimSubmissionBenefitsApi(res);
    return response.data;
  }
);

export const salesForceHealthBenefitsSlice = createSlice({
  name: 'benefits',
  initialState,
  
  reducers: {
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(salesForceHealthBenefitsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(salesForceHealthBenefitsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(salesForceHealthBenefitsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = salesForceHealthBenefitsSlice.actions;
export const getSalesForceHealthBenefits = (state: RootState) => state.salesForceHealthBenefits.value;
export const isSalesForceHealthBenefitsLoading = (state: RootState) => state.salesForceHealthBenefits.status === 'loading';

export default salesForceHealthBenefitsSlice.reducer;